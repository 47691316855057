import * as React from "react"
import { graphql, Link } from "gatsby"
import { DateTime } from "luxon";
import Header from "../components/site/Header"
import Footer from "../components/site/Footer"
const entities = require("entities");
import Excerpt, { IBlogExcerpt } from "../components/blog/excerpt"


// interface IProps {
//   data: {
//     allMarkdownRemark: {
//       totalCount: number
//       edges: {
//         node: {
//           id: string
//           frontmatter: {
//             title: string
//             date: string
//             description: string
//             cover: {
//               childImageSharp: {
//                 fluid: FluidObject
//               }
//             }
//             fields: {
//               slug: string
//             }
//             excerpt: string
//           }
//         }
//       }[]
//     }
//   }
// }

interface IProps {
  data: {
    allMdx: {
      edges: {
        node: IBlogExcerpt
      }[]
    }
  }
}

class Index extends React.Component<IProps> {
  render() {
    // console.log(this.props)
    // const posts = this.props.data.postgres.posts.nodes.map(
    //   (e: any) => e.node,
    // )
    const posts = this.props.data.allMdx.edges

    return (
      <div className="relative bg-gray-50 h-screen">

        <div className="flex flex-col h-full">
          <div className="flex-grow">
            <Header/>
            <main className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
        <div className="overflow-hidden mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">

          <div className="text-center">
            <h2
              className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl"
            >
              We make
              <br className="xl:hidden"/>
              <span className="text-indigo-600"> email</span> better
            </h2>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Word to the Wise help you create more effective email programs and infrastructure.<br/>
              We help you navigate business, technology, and policy challenges so your message reaches your customers.
            </p>
            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md shadow">
                <Link
                  to="/start/"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                >
                  Get started
                </Link>
              </div>
            </div>
          </div>
        </div>
              {/* Blog snippets*/}
              <div className="mt-12 grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">
                {posts.map((post, idx) => {
                  return (
                  <Excerpt key={idx} node={post.node} />
                )})}
              </div>
        {/*<p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?</p>*/}
            </main>
          </div>
          <Footer/>
        </div>
      </div>
    )
  }
}

export default Index

// export const query = graphql`
//   query {
//     allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
//       totalCount
//       edges {
//         node {
//           id
//           frontmatter {
//             title
//             date(formatString: "DD MMMM, YYYY")
//             description
//             cover {
//               childImageSharp {
//                 ... on ImageSharp {
//                   fluid(maxWidth: 800, maxHeight: 400) {
//                     ...GatsbyImageSharpFluid_withWebp
//                   }
//                 }
//               }
//             }
//           }
//           fields {
//             slug
//           }
//           excerpt
//         }
//       }
//     }
//   }
// `

// export const query = graphql`
//     query firstThree {
//         postgres {
//             posts(orderBy: DATE_DESC, first: 3) {
//                 nodes {
//                     nodeId
//                     authorByAuthor {
//                         slug
//                         name
//                     }
//                     title
//                     path
//                     date
//                     postsTags {
//                         nodes {
//                             tag {
//                                 slug
//                                 name
//                             }
//                         }
//                     }
//                     postsCategories {
//                         nodes {
//                             category {
//                                 slug
//                                 name
//                             }
//                         }
//                     }
//                     excerpt
//                 }
//             }
//         }
//     }
// `

export const query = graphql`
    query frontExcerpts {
        allMdx(sort: {order: DESC, fields: frontmatter___date}, filter: {frontmatter: {draft: {eq: false}}}, limit: 3) {
            edges {
                node {
                    fields {
                        slug
                        categorySlugs {
                            name
                        }
                        tagSlugs {
                            name
                        }
                        excerpt
                        authorSlug
                    }
                    frontmatter {
                        author
                        date
                        prettyDate: date(formatString: "MMMM Do, YYYY")
                        title
                    }
                    id
                }
            }
        }
    }
`